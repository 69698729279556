<script>
export default {
  name: "MonthlySummary"
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <h4 class="mb-3 text-center">Overview of PSC inspection by management office / group</h4>
      <p class="lead text-black-50 mb-0">Monthly Summary</p>
      <table class="table text-center table-sm small e-tbl">
        <thead class="bg-white">
        <tr>
          <th colspan="3"></th>
          <th class="align-middle" scope="col">JAN</th>
          <th class="align-middle" scope="col">FEB</th>
          <th class="align-middle" scope="col">MAR</th>
          <th class="align-middle" scope="col">APR</th>
          <th class="align-middle" scope="col">MAY</th>
          <th class="align-middle" scope="col">JUN</th>
          <th class="align-middle" scope="col">JUL</th>
          <th class="align-middle" scope="col">AUG</th>
          <th class="align-middle" scope="col">SEP</th>
          <th class="align-middle" scope="col">OCT</th>
          <th class="align-middle" scope="col">NOV</th>
          <th class="align-middle" scope="col">DEC</th>
          <th class="align-middle" scope="col">Total</th>
        </tr>
        </thead>
        <tbody id="monthlySummaryBody">
          <tr v-show="loading"><td colspan="16" class="align-middle"><app-loading></app-loading></td></tr>
          <template v-show="!loading">
          <tr id="monthlySummaryPcc"></tr>
          <tr id="monthlySummaryBlk1"></tr>
          <tr id="monthlySummaryBlk2"></tr>
          <tr id="monthlySummaryTotalKrbs"></tr>
          <tr id="monthlySummaryRohq"></tr>
          <tr id="monthlySummaryStargateOgd"></tr>
          <tr id="monthlySummaryStargateEccd"></tr>
          <tr id="monthlySummaryOther"></tr>
          <tr><td colspan="16" style="border-left: none; border-right: none"></td></tr>
          <tr ref="monthlySummaryNoDeciency"></tr>
          <tr ref="monthlySummaryInspection"></tr>
          <tr ref="monthlySummaryMonthlyAvg"></tr>
          <tr ref="monthlySummaryTotalAvg"></tr>
          </template>
        </tbody>
      </table>
    </div>

    <div class="col-12">
      <p class="lead text-black-50 mb-0">Past Record</p>
      <table class="table text-center table-sm small e-tbl">
        <thead class="bg-white">
        <tr>
          <th></th>
          <th class="align-middle" scope="col">JAN</th>
          <th class="align-middle" scope="col">FEB</th>
          <th class="align-middle" scope="col">MAR</th>
          <th class="align-middle" scope="col">APR</th>
          <th class="align-middle" scope="col">MAY</th>
          <th class="align-middle" scope="col">JUN</th>
          <th class="align-middle" scope="col">JUL</th>
          <th class="align-middle" scope="col">AUG</th>
          <th class="align-middle" scope="col">SEP</th>
          <th class="align-middle" scope="col">OCT</th>
          <th class="align-middle" scope="col">NOV</th>
          <th class="align-middle" scope="col">DEC</th>
          <th class="align-middle" scope="col">Total</th>
        </tr>
        </thead>
        <tbody ref="pscSummaryPastRecords"></tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AppLoading from "@/components/elements/AppLoading.vue";

export default {
  name: 'PscMonthlySummary',
  data() {
    return {
      loading: false,
    }
  },
  components: {
    'app-loading': AppLoading,
  },
  computed: {
    ...mapGetters(['inspectionMonthlySummary'])
  },
  methods: {
    ...mapActions(['getInspectionMonthlySummary']),
    populatePscMonthlySummary() {
      let monthlySummary = this.inspectionMonthlySummary;
      let monthlySummaryPastRecords = Object.entries(monthlySummary.pastRecords);
      let domMonthlySummaryNoDeciency = this.$refs.monthlySummaryNoDeciency;
      let domMonthlySummaryInspection = this.$refs.monthlySummaryInspection;
      let domMonthlySummaryMonthlyAvg = this.$refs.monthlySummaryMonthlyAvg;
      let domMonthlySummaryTotalAvg = this.$refs.monthlySummaryTotalAvg;
      let domPscSummaryPastRecords = this.$refs.pscSummaryPastRecords;

      ['pcc', 'blk1', 'blk2', 'total_krbs', 'rohq', 'stargate_ogd', 'stargate_eccd', 'other'].forEach(key => {
        let selectorClass = 'monthlySummary';
        let selector = key.replace('total_k', 'totalK');
        selector = selector.replace('stargate_e', 'stargateE');
        selector = selector.replace('stargate_o', 'stargateO');
        selector = selector.charAt(0).toUpperCase() + selector.slice(1);
        selectorClass += selector;
        selector = '#monthlySummary' + selector;
        let appendableNoDeficiency = '<td class="smaller-font-size ">No Deficiency</td>';
        let appendableInspection = '<td class="smaller-font-size ">Nbr Inspection</td>';
        let appendableRate = '<td class="smaller-font-size ">Rate (%)</td>';
        let current = monthlySummary.current[key];
        let isClean = (current.hasOwnProperty('total') && current.total.no_deficiency_count == 0 && current.total.inspection_count == 0);

        if (key == 'other' && isClean) {
          return;
        }

        Object.keys(current).forEach(key => {
          let target = current[key];
          appendableNoDeficiency += '<td>' + target.no_deficiency_count + '</td>';
          appendableInspection += '<td>' + target.inspection_count + '</td>';
          appendableRate += '<td>' + (target.ndr).toFixed(1) + '%</td>';
        });

        const selectorAppend = {
          pcc: '<td rowspan="12" class="monthlySummaryPcc align-middle">KRBS Kobe</td><td rowspan="3" class="monthlySummaryPcc align-middle">PCC</td>',
          blk1: '<td rowSpan="3" class="monthlySummaryBlk1 align-middle">BLK1</td>',
          blk2: '<td rowSpan="3" class="monthlySummaryBlk2 align-middle">BLK2</td>',
          total_krbs: '<td rowSpan="3" class="monthlySummaryTotalKrbs align-middle">Total</td>',
          rohq: '<td rowSpan="3" colSpan="2" class="monthlySummaryRohq align-middle">ROHQ</td>',
          stargate_ogd: '<td rowSpan="3" colSpan="2" class="monthlySummaryStargateOgd align-middle">Stargate Bremen<br>(OGD)</td>',
          stargate_eccd: '<td rowSpan="3" colSpan="2" class="monthlySummaryStargateEccd align-middle">Stargate Bremen<br>(ECCD)</td>',
          other: '<td rowspan="3" colspan="2" class="monthlySummaryOther align-middle">Other</td>',
        };

        if (!isClean && selectorAppend.hasOwnProperty(key)) {
          $(selector).append(selectorAppend[key]);
        }

        $(selector).append(appendableNoDeficiency);
        $(selector).after('<tr class="' + selectorClass + '">' + appendableInspection + '</tr><tr class="' + selectorClass + '">' + appendableRate + '</tr>');

        if (isClean) {
          $(selector).hide();
          $('.' + selectorClass).hide();
        }
      });

      let appendPastRecords = '';
      monthlySummaryPastRecords.map(function (yearlyItems, index) {
        let year = yearlyItems[0].replace('year_', '');
        let monthlyItems = yearlyItems[1];

        if (!index) {
          let appendable = {
            no_deficiency_count: '<td colspan="3" class="">No Deficiency</td>',
            inspection_count: '<td colspan="3" class="">Number of Inspection</td>',
            monthly_avg: '<td colspan="3" class="">Monthly Average</td>',
            total_avg: '<td colspan="3" class="">Total Average</td>',
            inspection_count_total: 0, no_deficiency_total: 0, monthly_avg_total: 0, total_avg_total: 0,
          };

          monthlyItems.forEach(item => {
            appendable.no_deficiency_count += '<td class="align-middle">' + (item.no_deficiency_count ? item.no_deficiency_count : '') + '</td>';
            appendable.inspection_count += '<td class="align-middle">' + (item.inspection_count ? item.inspection_count : '') + '</td>';
            appendable.monthly_avg += '<td class="align-middle">' + (item.monthly_avg ? item.monthly_avg + '%' : '') + '</td>';
            appendable.total_avg += '<td class="align-middle">' + (item.total_avg ? item.total_avg + '%' : '') + '</td>';
            appendable.no_deficiency_total += parseFloat(item.no_deficiency_count);
            appendable.inspection_count_total += parseFloat(item.inspection_count);
            if (item.total_avg)
              appendable.total_avg_total = item.total_avg;
          });

          appendable.monthly_avg_total = appendable.total_avg_total;
          appendable.no_deficiency_count += '<td class="align-middle">' + (appendable.no_deficiency_total ? appendable.no_deficiency_total : '') + '</td>';
          appendable.inspection_count += '<td class="align-middle">' + (appendable.inspection_count_total ? appendable.inspection_count_total : '') + '</td>';
          appendable.monthly_avg += '<td class="align-middle">' + (appendable.monthly_avg_total ? appendable.monthly_avg_total + '%' : '') + '</td>';
          appendable.total_avg += '<td class="align-middle"></td>';
          domMonthlySummaryNoDeciency.innerHTML = appendable.no_deficiency_count;
          domMonthlySummaryInspection.innerHTML = appendable.inspection_count;
          domMonthlySummaryMonthlyAvg.innerHTML = appendable.monthly_avg;
          domMonthlySummaryTotalAvg.innerHTML = appendable.total_avg;
        }
        // else {
          appendPastRecords += '<tr><td>' + year + '</td>';
          let monthly_avg_total = 0;
          let total_avg_total = 0;

          monthlyItems.forEach(item => {
            monthly_avg_total += parseFloat(item.monthly_avg);
            total_avg_total = (item.total_avg || item.total_avg != 0) ? parseFloat(item.total_avg) : total_avg_total;
            appendPastRecords += '<td>' + (item.monthly_avg ? item.monthly_avg + '%' : '0%') + '</td>';
          });

          appendPastRecords += '<td>' + ((total_avg_total == 0 ? 0 : total_avg_total.toFixed(1)) + '%') + '</td></tr>';
          domPscSummaryPastRecords.innerHTML = appendPastRecords;
        // }
      });

      $('#monthlySummaryBody').show();
    },
  },
  async created() {
    this.loading = true;
    await this.getInspectionMonthlySummary();

    this.loading = false;
    this.populatePscMonthlySummary();
  }
}
</script>

<style scoped>
.smaller-font-size {
  font-size: 10px!important;
}
</style>
